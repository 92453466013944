/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-plus-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.293 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.707A1 1 0 0013.707 4L10 .293A1 1 0 009.293 0M9.5 3.5v-2l3 3h-2a1 1 0 01-1-1M8.5 7v1.5H10a.5.5 0 010 1H8.5V11a.5.5 0 01-1 0V9.5H6a.5.5 0 010-1h1.5V7a.5.5 0 011 0"/>',
    },
});
